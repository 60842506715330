import { post } from "../methods";
function authToken(payload, headers = {}) {
  return post({
    url: "/backend/auth/token",
    payload,
    headers
  });
}
export {
  authToken as default
};
