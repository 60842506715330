import { useCallback } from 'react';

async function getStorageObject() {
  if (RUNTIME_ENV === 'native') {
    const { Preferences } = await import('@capacitor/preferences');
    return {
      set(key: string, value: string) {
        Preferences.set({ key, value });
      },
      async get(key: string) {
        const result = await Preferences.get({ key });
        return result.value;
      },
      flush(key: string) {
        Preferences.remove({ key });
      },
    };
  } else {
    return {
      set(key: string, value: string) {
        window.localStorage.setItem(key, value);
      },
      async get(key: string) {
        return window.localStorage.getItem(key);
      },
      flush(key: string) {
        window.localStorage.removeItem(key);
      },
    };
  }
}
/**
 * Persistent storage wrapper for read/write operations.
 */
export function usePersistentStorage() {
  const set = useCallback(async (key: string, value: string) => {
    try {
      const { set } = await getStorageObject();
      set(key, value);
    } catch {
      console.warn('Unable to set item in storage');
    }
  }, []);
  const get = useCallback(async (key: string) => {
    try {
      const { get } = await getStorageObject();
      return await get(key);
    } catch {
      return null;
    }
  }, []);

  const flush = useCallback(async (key: string) => {
    try {
      const { flush } = await getStorageObject();
      flush(key);
    } catch {
      // silently fail
    }
  }, []);

  return { set, get, flush };
}
