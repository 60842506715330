import type { ComponentPropsWithoutRef } from 'react';
import type { Apple } from '@/components/OAuthButtons';
import type { AuthType } from './types';

import { useMutation, useQueryClient } from '@tanstack/react-query';
import { authToken } from '@simplywallst/services';
import { SWS_CLIENT_ID, AUTH_SCOPE } from '@/constants/env';
import { useHandleLoginResponse } from './useHandleLoginResponse';
import { useBrokerToken } from '../useBrokerToken';

type AuthPayload = Parameters<typeof authToken>[0];

type SignInParams = {
  type: AuthType;
  token?: string;
  user?: Parameters<ComponentPropsWithoutRef<typeof Apple>['onSuccess']>[1];
  username?: string;
  password?: string;
  referralData?: {
    referralCoupon?: string;
    referralId?: string;
    referralToken?: string;
  };
};

export function useLogin({
  onSuccess = () => undefined,
  onError,
}: {
  onSuccess?: (
    token: Awaited<ReturnType<typeof authToken>> & { provider: AuthType }
  ) => void;
  onError?: (error: unknown) => void;
}) {
  const { handleLoginResponse } = useHandleLoginResponse();
  const brokerToken = useBrokerToken();
  const queryClient = useQueryClient();
  return useMutation<
    Awaited<ReturnType<typeof authToken>> & { provider: AuthType },
    unknown,
    SignInParams,
    unknown
  >({
    mutationFn: async (params) => {
      const authPayload: Partial<AuthPayload> = {
        client_id: SWS_CLIENT_ID,
        cross_client: RUNTIME_ENV === 'native',
        provider: params.type,
        scope: AUTH_SCOPE,
        source: 'react',
      };
      if (
        params.type === 'Apple' ||
        params.type === 'Facebook' ||
        params.type === 'Google'
      ) {
        /** append social params */
        authPayload['grant_type'] = 'social';
        if (typeof params.token === 'string') {
          authPayload['token'] = params.token;
        }
        if (typeof params.user !== 'undefined') {
          authPayload['user'] = JSON.stringify(params.user);
        }
        authPayload['referral_data'] = JSON.stringify(params.referralData);
      }
      if (params.type === 'sws') {
        authPayload['grant_type'] = 'password';
        authPayload['password'] = params.password;
        authPayload['username'] = params.username;
      }
      if (typeof brokerToken === 'string' && brokerToken !== '') {
        authPayload['broker_token'] = brokerToken;
      }
      const authTokenResponse = await authToken(
        authPayload as Required<AuthPayload>
      );

      return { ...authTokenResponse, provider: params.type };
    },
    onSuccess(authTokenResponse) {
      handleLoginResponse(authTokenResponse);
      onSuccess(authTokenResponse);
      queryClient.invalidateQueries();
    },
    onError,
  });
}
