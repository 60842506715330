import axios from 'axios';
import useUser from '@/hooks/useUser';
import { useQuery } from '@tanstack/react-query';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { getAccessTokenFromOtp } from '@simplywallst/services';
import { useHandleLoginResponse } from './useHandleLoginResponse';
import type { LocationState } from 'redux-first-router';
import { pathToAction, redirect } from 'redux-first-router';
import {
  ROUTE_COMPANY,
  ROUTE_DASHBOARD,
  ROUTE_STOCKS_HOME,
} from '@/constants/routes';
import routesGenerator from '@/router/routesGenerator';
import { useEffect } from 'react';
import queryString from 'query-string';

const routesMap = routesGenerator();
export function useOTP() {
  const user = useUser();
  const dispatch = useDispatch();

  const { handleLoginResponse } = useHandleLoginResponse();
  const otpRoute = useSelector<
    { location: LocationState },
    { token: string; redirect?: string; confirmEmail?: string }
  >((state) => {
    const { payload, query } = state.location;
    return {
      token: payload.token,
      redirect: query?.r,
      confirmEmail: query?.confirm_email,
    };
  }, shallowEqual);

  const otpQuery = useQuery({
    queryKey: ['otp', otpRoute.token, otpRoute.confirmEmail] as const,
    queryFn: async (context) => {
      const { 1: token, 2: confirmEmail } = context.queryKey;
      const response = await getAccessTokenFromOtp({
        auth_otp: token,
        confirm_email: confirmEmail,
      });

      if (response.data.confirm_email === 'stale') {
        throw new Error('stale account');
      }
      return response;
    },
    enabled: user.isFetched && typeof otpRoute.token === 'string',
    retry: false,
    refetchOnWindowFocus: false,
    refetchOnMount: false,
  });

  const { data, status } = otpQuery;

  useEffect(() => {
    if (status === 'success') {
      const onSuccess = async () => {
        /**
         *
         * react-query narrowing
         * if `confirm_email` is `stale` the `queryFn` will throw
         * therefore will never trigger this function
         *
         * */
        if (data.data.confirm_email !== 'stale') {
          const { confirm_email, ...dataWithoutConfirmEmail } = data.data;
          await handleLoginResponse({
            ...data,
            data: dataWithoutConfirmEmail,
            provider: 'sws',
          });

          /**
           *
           * If confirm_email is `complete` means account is recently
           * created and have just validated their account. Thus, trigger
           * onboarding here.
           *
           * */
          if (confirm_email === 'complete') {
            let registeredOnCompanyPage = false;
            let action: undefined | ReturnType<typeof pathToAction>;
            if (typeof otpRoute.redirect === 'string') {
              action = pathToAction(otpRoute.redirect, routesMap, queryString);
              registeredOnCompanyPage = [ROUTE_COMPANY].includes(action.type);
            }
            dispatch({
              type: 'UI__START_ONBOARDING',
              payload: {
                registeredOnCompanyPage,
              },
            });

            dispatch(
              typeof action === 'undefined'
                ? redirect({ type: ROUTE_STOCKS_HOME })
                : action
            );

            return;
          }

          /** confirm_email is undefined or null aka normal otp path */
          if (typeof otpRoute.redirect === 'string') {
            const action = pathToAction(
              otpRoute.redirect,
              routesMap,
              queryString
            );
            if (typeof action !== 'undefined') {
              dispatch(action);
              return;
            }
          }
          dispatch(redirect({ type: ROUTE_DASHBOARD }));
        }
      };
      onSuccess();
    }
  }, [data, status, dispatch, handleLoginResponse, otpRoute.redirect]);

  if (otpQuery.status === 'error') {
    const { error } = otpQuery;
    if (axios.isAxiosError(error)) {
      try {
        const [swsError] = error.response?.data.errors;
        return {
          status: otpQuery.status,
          message: swsError.detail as string,
          type: 'generic',
        } as const;
      } catch {
        /** ignore and let it fall through */
      }
    }

    if (error instanceof Error) {
      if (error.message === 'stale account') {
        return {
          status: 'error',
          type: 'stale',
        } as const;
      }
    }

    return {
      status: otpQuery.status,
      type: 'generic',
      message: 'An unknown error has occurred',
    } as const;
  }
  return { status: otpQuery.status };
}
