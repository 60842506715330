import useUser, { QUERY_KEY } from '@/hooks/useUser';
import { useCallback, useEffect } from 'react';
import { withAuth } from '@/utilities/queries';
import { updateCoupon } from '@simplywallst/services';
import { QueryClient, useMutation } from '@tanstack/react-query';
import { usePersistentStorage } from '../usePersistentStorage';
import { useSnackbar } from '@/features/snackbar';

/**
 *
 * User coupon hook to help manage coupon implementation.
 *
 * Stores the coupon query string into a persistent storage.
 * If viewed by an unauthenticated user, the coupon will be
 * stored in localStorage and is automatically applied
 * when the user registers or logs in.
 *
 */
export function useCoupon() {
  const { addSnackbar } = useSnackbar();
  const { set, get, flush } = usePersistentStorage();
  const { data: userData, isFetched } = useUser();
  const queryClient = new QueryClient();
  const {
    loggedIn,
    billing: { coupon },
  } = userData;
  const mutation = useMutation({
    mutationFn: async (coupon: string) => {
      const result = await withAuth(updateCoupon, coupon);
      return result.data;
    },
    mutationKey: ['updateCoupon'],
    onSuccess() {
      /**
       *
       * The billing api responds with an outdated coupon object. So we
       * can't use the response data from the `updateCoupon` service. Invalidate
       * and refetch `/api/user` instead.
       *
       * */
      queryClient.invalidateQueries({ queryKey: QUERY_KEY });
    },
    onError() {
      addSnackbar({
        message: 'Coupon is invalid',
        type: 'warning',
        actionLabel: 'OK',
        lifeTime: 5000,
      });
    },
    onSettled() {
      /** Success or error clear stored coupon if any. */
      flush(persistentStorageKey);
    },
  });
  const { mutate } = mutation;
  const persistentStorageKey = 'coupon';

  /**
   *
   * Scenarios
   *
   * Guest user:
   * 1. Lands on `/plans` page with `?coupon=30off`
   * 2. `coupon=30off` gets stored in persistent storage
   *
   * Logged in user:
   * 1. Lands on `/plans` page with `?coupon=30off`
   * 2. `coupon=30off` gets sent to the api via `updateCoupon` endpoint
   */
  useEffect(() => {
    const coupon = getCouponParam();
    if (typeof coupon !== 'string') return;
    if (loggedIn && isFetched) {
      mutate(coupon);
    } else {
      set(persistentStorageKey, coupon);
    }
  }, [isFetched, loggedIn, set, mutate]);

  const registerCoupon = useCallback(async () => {
    const storedCoupon = await get(persistentStorageKey);
    const accountCoupon = coupon?.id;
    if (typeof storedCoupon === 'string' && storedCoupon !== accountCoupon) {
      mutate(storedCoupon);
    }
  }, [get, mutate, coupon]);

  return {
    registerCoupon,
    mutation,
    couponData: coupon,
  };
}

function getCouponParam() {
  const current = new URL(window.location.href);
  const coupon = current.searchParams.get('coupon');
  return coupon;
}
