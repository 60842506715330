import { get } from "../methods";
function getAccessTokenFromOtp(payload, headers = {}) {
  return get({
    url: `/backend/auth/otp`,
    payload,
    headers
  });
}
export {
  getAccessTokenFromOtp as default
};
