import type { AuthType } from './types';
import { useCallback } from 'react';
import { useQueryClient } from '@tanstack/react-query';
import { useDispatch, useSelector } from 'react-redux';
import { setAuthCookie, convertExpiresInToExpiresAt } from '@utilities/cookies';
import { QUERY_KEY as USER_KEY } from '@/hooks/useUser';
import { useCoupon } from '@/hooks/useCoupon';
import type { authToken } from '@simplywallst/services';
import { redirect } from 'redux-first-router';
import {
  ROUTE_ABOUT,
  ROUTE_CAREERS,
  ROUTE_DASHBOARD,
  ROUTE_HOME,
  ROUTE_PASSWORD_RESET,
  ROUTE_SOCIAL_TO_LOCAL,
  ROUTE_PLANS,
  ROUTE_STOCKS_HOME,
  ROUTE_USER_REGISTER,
  ROUTE_WELCOME,
  ROUTE_WELCOME_LOCALE,
  ROUTE_USER_REGISTER_LOCALE,
} from '@/constants/routes';
import { getLocationType } from '@/redux/location/selectors';
import { useUtmExperiment } from '@/hooks/usePlanUtmExperiment';
import { getActionFromPath, useLocation } from '../useLocation';

/**
 *
 * Helper hook that returns a function that can be called after
 * a user logs in.
 *
 */
export function useHandleLoginResponse() {
  const dispatch = useDispatch();
  const queryClient = useQueryClient();
  const { registerCoupon } = useCoupon();
  const type = useSelector(getLocationType);
  const { isUtmExperiment } = useUtmExperiment();

  const location = useLocation();
  const r = location.query?.r;

  const handleLoginResponse = useCallback(
    async (
      response: { provider: AuthType } & Awaited<ReturnType<typeof authToken>>
    ) => {
      const { access_token, refresh_token, expires_in, created } =
        response.data;
      try {
        const expiresAt = convertExpiresInToExpiresAt(expires_in);
        setAuthCookie(access_token, refresh_token, expiresAt);
      } catch (error) {
        console.error(error);
      }

      registerCoupon();

      await queryClient.refetchQueries({ queryKey: USER_KEY });

      /**
       * prioritise `r` param if available
       */

      const redirectAction = getActionFromPath(r);

      if (typeof redirectAction !== 'undefined') {
        dispatch(
          redirect({
            type: redirectAction.type,
            payload: redirectAction.payload,
            // @ts-expect-error action.meta is an object
            query: redirectAction.query ?? redirectAction.meta?.query ?? {},
          })
        );
      } else {
        /** only redirect if we're on these pages */
        const isPlanPageWithoutUtmExperiment =
          type === ROUTE_PLANS && !isUtmExperiment;

        if (
          [
            ROUTE_HOME,
            ROUTE_WELCOME,
            ROUTE_WELCOME_LOCALE,
            ROUTE_USER_REGISTER,
            ROUTE_USER_REGISTER_LOCALE,
            ROUTE_ABOUT,
            ROUTE_CAREERS,
            ROUTE_PASSWORD_RESET,
            ROUTE_SOCIAL_TO_LOCAL,
          ].includes(type) ||
          isPlanPageWithoutUtmExperiment
        ) {
          if (created) {
            dispatch(
              redirect({
                type: ROUTE_STOCKS_HOME,
              })
            );
          } else {
            dispatch(
              redirect({
                type: ROUTE_DASHBOARD,
              })
            );
          }
        }
      }
    },
    [dispatch, queryClient, registerCoupon, type, r]
  );

  return {
    handleLoginResponse,
  };
}
