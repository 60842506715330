import { useEffect, useState } from 'react';

/**
 * A React hook that gets the `broker` search parameter from the current URL.
 *
 * @returns {string} The `broker` search parameter if it exists, otherwise an empty string.
 */
export function useBrokerToken() {
  const [brokerToken, setBrokerToken] = useState('');
  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const broker = urlParams.get('broker');
    setBrokerToken(broker || '');
  }, []);
  return brokerToken;
}
