import { patch } from "../methods";
function updateCoupon(id, headers = {}) {
  return patch({
    url: `api/user/billing`,
    payload: { coupon: id, gateway: "stripe" },
    headers
  });
}
export {
  updateCoupon as default
};
